import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'evin-user-exports',
  templateUrl: './user-exports.component.html',
  styleUrls: ['./user-exports.component.css']
})
export class UserExportsComponent extends BaseComponent implements OnInit {
  
  crntPage = 1; pageSize = environment.tblPageSize; totalItems: any; pageEntrs = ''; exportsList: any[] = []; ttlItems: any;
  searchText: any; noRcrdsTxt = environment.TblNoRcrds;  filterMetadata = { count: 0 }; count: any; minFD: any = null;
  meFrm: FormGroup = this.fb.group({ fromDate: [''], reportName: [''], toDate: [''], userId: [''] }); lstFTs: any[] = [];
  lgnUsrDtls = JSON.parse(<any>localStorage.getItem('lgnUsrDtls'));

  constructor(public glblSvc: GlobalService,
    private router: Router,
    public fb: FormBuilder) {
    super(glblSvc);
    glblSvc.sbjctMstrData.subscribe(s => this.lstFTs = s.fileTyps);
  }

  ngOnInit(): void {
    this.meFrm.get('userId')?.setValue(this.lgnUsrDtls.userName), this.meFrm.get('fromDate')?.setValue(this.wkBkDt), setTimeout(() => {
      this.meFrm.get('toDate')?.setValue(this.dt2Day), this.LoadExports(); }, 100);
  }

  onChngDT(evnt: any) {
    this.meFrm.get('toDate')?.setValue(null), this.minFD = evnt;
  }

  onDwnld(item: any) {
    if (item.filePath)
      this.OpenFileInNewTab(item.filePath);
  }

  onRst() {
    this.meFrm.reset(), setTimeout(() => { this.meFrm.get('reportName')?.setValue(''), this.ngOnInit(); }, 100);
  }

  clearBSDate(ctrl: string) {
    this.meFrm.get(ctrl)?.setValue('');
  }

  LoadExports() {
    let crntPgIndx = this.crntPage - 1, payLoad: any = this.meFrm.getRawValue();
    if (this.CheckDaysDiff(payLoad.fromDate, payLoad.toDate) > 120) {
      this.glblSvc.onTstr('w', 'Difference between From and To dates should not exceed 120 days');
      return;
    }
    payLoad.fromDate = this.glblSvc.setDtFrmt(payLoad.fromDate, this.dtF.ymd), payLoad.toDate = this.glblSvc.setDtFrmt(payLoad.toDate,
      this.dtF.ymd), this.FetchData(environment.apiCalls.post, environment.apiPaths.file, environment.apiMthds.usrExprtsData.
        replace('ttt', `${crntPgIndx}`).replace('sss', `${this.pageSize}`), payLoad, 3, true).then(() => {
      if (this.result) {
        if (this.result.data)
          this.totalItems = this.result.data?.count ?? 0, this.ttlItems = this.totalItems,
            this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems);
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.exportsList = this.result.data?.filesList;
          else if (this.result.data == null)
            this.glblSvc.onTstr('i', this.result.message);
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo, this.LoadExports();
  }
  
  search() {
    this.count = this.searchText == '' ? this.ttlItems : this.filterMetadata.count, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems);
  }
  
}