<form [formGroup]="astCapctyForm" autocomplete="off">
    <div class="card p-0 border-0">
        <div class="row mt-2 mb-0 p-3">
            <div class="col-xl-12">
                <div class="form-row row mb-2">
                    <div class="form-group col-xl-3">
                        <strong class="col-form-label">Facility By<span class="text-danger"><sup>*</sup></span></strong>
                        <div class="form-check form-check-inline mt-2 p-l-25">
                            <input class="form-check-input" type="radio" id="rbFac1" name="isFacBdge" value="badge"
                                [(ngModel)]="isFacBdge" [checked]="isFacBdge=='badge'" formControlName="isFacBdge"
                                (click)="onClkRdb('storeTags')">
                            <label class="form-check-label" for="fac">Tag</label>
                        </div>
                        <div class="form-check form-check-inline" *ngIf="!ChkNtnlUsrByPrnthId()">
                            <input class="form-check-input" type="radio" id="rbFac2" name="isFacBdge" value="name"
                                [(ngModel)]="isFacBdge" [checked]="isFacBdge=='name'" formControlName="isFacBdge"
                                (click)="onClkRdb('storeIds')">
                            <label class="form-check-label" for="fac">Name</label>
                        </div>
                        <div *ngIf="isFacBdge=='badge'">
                            <ng-multiselect-dropdown [settings]="dropdownSettings"
                                [data]="lstFacTags" formControlName="storeTags"
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.storeTags.errors }"                                                                        
                                (onSelect)="onMultiSlct('s', 'f', $event)"
                                (onSelectAll)="onMultiSlct('a', 'f', $event)"
                                (onDeSelect)="onMultiSlct('r', 'f', $event)"
                                (onDeSelectAll)="onMultiSlct('d', 'f', $event)"
                                [(ngModel)]="slctdFac">
                            </ng-multiselect-dropdown>
                            <div *ngIf="isSbmtd && fc.storeTags.errors" class="invalid-feedback">
                                <div *ngIf="fc.storeTags.errors?.required">Facility tag is required</div>
                            </div>
                        </div>
                        <div *ngIf="isFacBdge=='name'">
                            <ng-multiselect-dropdown [settings]="ddlStngs" [data]="lstFacIds" [(ngModel)]="slctdFacId"
                            formControlName="storeIds" (onSelect)="onMultiSlct('s', 'c', $event)"
                            (onSelectAll)="onMultiSlct('a', 'c', $event)" (onDeSelect)="onMultiSlct('r', 'c', $event)"
                            (onDeSelectAll)="onMultiSlct('d', 'c', $event)"
                            [ngClass]="{ 'is-invalid': isSbmtd && fc.storeIds.errors }" >
                        </ng-multiselect-dropdown>
                        <div *ngIf="isSbmtd && fc.storeIds.errors" class="invalid-feedback">
                            <div *ngIf="fc.storeIds.errors?.required">Facility name is required</div>
                        </div>
                        </div>
                    </div>
                    <div class="form-group col-xl-3">
                        <strong class="col-form-label">CCE Type<span class="text-danger"><sup>*</sup></span></strong>
                        <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="astTypLst" [(ngModel)]="astType"
                            formControlName="assetType" (onSelect)="onMultiSlct('s', 't', $event)"
                            (onSelectAll)="onMultiSlct('a', 't', $event)" (onDeSelect)="onMultiSlct('r', 't', $event)"
                            (onDeSelectAll)="onMultiSlct('d', 't', $event)"
                            [ngClass]="{ 'is-invalid': isSbmtd && fc.assetType.errors }" >
                        </ng-multiselect-dropdown>
                        <div *ngIf="isSbmtd && fc.assetType.errors" class="invalid-feedback">
                            <div *ngIf="fc.assetType.errors?.required">CCE Type is required</div>
                        </div>
                    </div>
                    <div class="form-group col-xl-3 mt-3">
                        <strong for="textBox">State</strong>
                        <select formControlName="state" class="form-select form-select-sm"
                            (change)="dstLst=[];blkLst=[];onLocChng($event, 'state')" #locState>
                            <option value="null">All</option>
                            <option *ngFor="let c of stsLst" [value]="c.id">{{c.name}}</option>
                        </select>
                    </div>
                    <div class="form-group col-xl-3 mt-3">
                        <strong for="textBox">District</strong>
                        <select formControlName="district" class="form-select form-select-sm" #locDist>
                            <option value="null">All</option>
                            <option *ngFor="let s of dstLst" [value]="s.districtid">{{s.districtname}}</option>
                        </select>
                    </div>
                    <div class="form-group col-xl-3 mt-3" *ngIf="false">
                        <div>
                            <strong for="textBox">Taluk/Block</strong>
                            <select formControlName="block" class="form-select form-select-sm" #locBlck>
                                <option value="null">All</option>
                                <option *ngFor="let b of blkLst" [value]="b.blockid">{{b.blockname}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group col-xl-12 mt-2">
                <div class="float-xl-end mb-2">
                    <button class="btn btn-sm btn-light me-2" type="button" (click)="onCncl();isSbmtd = false;lstGrid = [];">Reset</button>
                    <button class="btn btn-sm btn-primary" type="button" (click)="GenRprt();isSbmtd=true">Get report</button>
                </div>
            </div>
        </div>
        <div class="row mt-3 mb-2">
            <div class="col-xl-1"></div>
            <div class="col-xl-12">
                <div class="card h-100 mb-0">
                    <div class="card-body p-3">
                        <nav aria-label="breadcrumb" class="float-end">
                            <ol class="breadcrumb" *ngIf="brdcrmData.length!=0">
                                <li class="breadcrumb-item active" *ngFor="let item of brdcrmData;" (click)="onClkBrdcrumb(item)"><a>{{item.brdName}}</a></li>
                            </ol>
                        </nav>
                        <div class="clearfix"></div>
                        <div class="row mt-2">
                            <div class="col-xl-12 mb-2">
                                <div class="row" *ngIf="lstGrid?.length!=0">
                                    <div class="col-xl-12">
                                        <button class="btn btn-sm btn-success" (click)="isExprt=true;onEE()">
                                            <i class="fa fa-file-excel-o"></i>&nbsp;Export
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6" >
                                <div *ngIf="lstGrid?.length!=0" class="mt-2">{{pageEntrs}}</div>
                            </div>
                            <div class="col-xl-6" >
                                <div class="float-end" *ngIf="lstGrid?.length!=0">
                                    <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive table_spacing">
                            <table class="table table-striped table-sm table-bordered border-primary f-14">
                                <thead>
                                    <tr class="text-center">
                                        <th>Item.No</th>
                                        <th>State Name</th>
                                        <th *ngIf="isDstShw">District Name</th>
                                        <th *ngIf="isFcltyShw">Facility Name</th>
                                        <th class="col-lg-4 text-center">Asset capacity (litres)</th>
                                        <th>Vaccine Occupied Capacity (litres)</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="lstGrid?.length!=0">
                                    <tr class="text-center pntr" *ngFor="let item of lstGrid | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems }; let i=index">
                                        <td>{{(pageSize*(crntPage-1))+i+1}}</td>
                                        <td (click)="onDmnClk(item, 's')">{{item.stateName}}</td>
                                        <td *ngIf="isDstShw" (click)="onDmnClk(item, 'd')">{{item.districtName}}</td>
                                        <td *ngIf="isFcltyShw">{{item.storeName}}</td>
                                        <td>{{item.capacity}}</td>
                                        <td>{{item.occupied}}</td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="lstGrid?.length==0">
                                    <tr>
                                        <td colspan="6" class="text-center">{{noRcrdTxt}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="pb-1 pt-2">
                            <div class="row" *ngIf="lstGrid?.length!=0">
                                <div class="col-xl-6 text-start">
                                    {{pageEntrs}}
                                </div>
                                <div class="col-xl-6">
                                    <div class="float-end">
                                        <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>