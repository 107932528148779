import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

declare let $: any;

@Component({
  selector: 'evin-transactions-list',
  templateUrl: './transactions-list.component.html',
  styleUrls: ['./transactions-list.component.css']
})

export class TransactionsListComponent extends BaseComponent implements OnInit {

  lstSlctdFacTags: any[] = []; lstShowFacTags: any[] = []; lstSlctdMatTags: any[] = []; lstShowMatTags: any[] = []; slctdMat: any[] = [];
  slctdFac: any[] = []; isFacAll = false; isMatAll = false; lstAllMats1: any[] = []; shwAPrds = true; lstFCF: any[] = []; lstStrs: any[] = [];
  lstMats: any[] = []; lstAllMats: any[] = []; lstTrans: any[] = []; noRcrdsTxt = environment.TblNoRcrds; pageEntrs = ''; crntPage = 1;
  pageSize = environment.tblPageSize; totalItems: any; dt2day = new Date();
  isPgLd = true; invtrasLstFrm = this.formBuilder.group({ txnsToDate: [this.dt2day], txnReason: [''], productBadge: [null], storeId: [null],
    storeBadge: [null], productId: [null], txnType: [''], txnsFromDate: [null], isActualTxn: [false], state: [null], district: [null],
    country: [null], block: [null], batchNo: [null] }); usrLvl = ''; frmDate = this.wkBkDt; isInitTxnDate = false; isShowDAT = false;
  slctdStrId: any; slctPrdId: any; lstMCF: any[] = []; lstTxnTyps: any[] = []; txnRsns: any[] = [];
  dropdownSettings = { singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL',
    itemsShowLimit: 3, allowSearchFilter: true }; dstLst: any[] = []; stsLst: any[] = []; blkLst: any[] = []; todt = new Date();
  
  constructor(public glblSvc: GlobalService,
    private router: Router,
    private formBuilder: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctStrList.subscribe(s => { this.lstStrs = s; }), this.glblSvc.sbjctBdgsList.subscribe(s => {
      this.lstMCF = s.filter(f => f.badgeTypeId == 1), this.lstFCF = s.filter(f => f.badgeTypeId == 2); }),
      this.glblSvc.sbjctMstrData.subscribe(s => { this.lstTxnTyps = s.txnTypeLst, this.txnRsns = s.rsnsLst, this.lstAllMats1 = s.allMats; });
    if (this.lstStrs.length == 1)
      this.invtrasLstFrm.get('storeId')?.setValue(this.lstStrs[0].storeName), this.slctdStrId = this.lstStrs[0].storeId, 
        this.shwAPrds = false, this.LoadMatsbyStoreId(this.slctdStrId);
  }

  ngOnInit(): void {
    this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst }), this.glblSvc.sbjctTabsPage.subscribe(s => {
      if (s == 'trans-create') this.LoadStoreTransactions(), this.onFormChanges(); });
    if (this.usrLgnDtls) {
      let cntryId = `${JSON.parse(<string>localStorage.getItem('lgnMstrsData')).cntriesLst.filter((f: any) => f.name == 'India')[0]?.id}` ?? null,
        usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
      this.usrLvl = usrLvlDtls?.userLevel ?? '', this.invtrasLstFrm.get('country')?.setValue(cntryId);
    }
    this.dmnLocation(), setTimeout(() => { this.onAS(); }, 1000); this.onTrnTyp('');
    //   if (this.usrLvl == 'State')
    //     this.invtrasLstFrm.get('state')?.setValue(usrLvlDtls?.stateId ?? null), this.onLocChng(usrLvlDtls?.stateId ?? null, 'state', true);
    //   if (this.usrLvl == 'District') {
    //     this.invtrasLstFrm.get('state')?.setValue(usrLvlDtls?.stateId ?? null), Promise.all([this.onLocChng(usrLvlDtls?.stateId ?? null,
    //       'state', true), this.onLocChng(usrLvlDtls?.districtId ?? null, 'district', true)]).then(() => {
    //         this.invtrasLstFrm.get('district')?.setValue(usrLvlDtls?.districtId ?? null), this.LoadStoreTransactions(), this.onFormChanges();
    //     });
    //   }
    //   if (this.usrLvl == 'Block')
    //     this.invtrasLstFrm.get('state')?.setValue(usrLvlDtls?.stateId ?? null),
    //       this.invtrasLstFrm.get('district')?.setValue(usrLvlDtls?.districtId ?? null),
    //       this.invtrasLstFrm.get('block')?.setValue(usrLvlDtls?.blockId ?? null);
    // }
    // if (this.slctDmnDetails?.districtId == null)
    //   this.LoadStoreTransactions(), this.onFormChanges();
  }

  onTrnTyp (e: any) {
    this.glblSvc.sbjctMstrData.subscribe(s => { this.txnRsns = s.rsnsLst; });
    let v = (e !='' ? e.target.value : e);
    (v == '1' ? (this.txnRsns = this.txnRsns?.filter((f: any) => f.reasonType == environment.cnfgTrnsRsnType.issue)) : (v == '2' ? 
      ( this.txnRsns = this.txnRsns?.filter((f: any) => f.reasonType == environment.cnfgTrnsRsnType.stk_in)) : (v == '3' ? ( this.txnRsns = 
        this.txnRsns?.filter((f: any) => f.reasonType ==  environment.cnfgTrnsRsnType.stk_edit)) : (v == '4' ? ( this.txnRsns = 
          this.txnRsns?.filter((f: any) => f.reasonType == environment.cnfgTrnsRsnType.discard)) : (v == '5' ? ( this.txnRsns = 
            this.txnRsns?.filter((f: any) => f.reasonType == environment.cnfgTrnsRsnType.transfer)) : this.txnRsns = [])))));
  }
  
  dmnLocation() {
    if (this.slctDmnDetails) {
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.invtrasLstFrm.get('state')?.setValue(+this.slctDmnDetails?.stateId ?? null), this.invtrasLstFrm.get('district')?.setValue(null),
          this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true);
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null) {
        this.invtrasLstFrm.get('state')?.setValue(+this.slctDmnDetails?.stateId ?? null), Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null,
          'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null, 'district', true)]).then(() => {
            this.invtrasLstFrm.get('district')?.disable(), this.invtrasLstFrm.get('district')?.setValue(+this.slctDmnDetails?.districtId ?? null),
              setTimeout(() => { this.invtrasLstFrm.get('block')?.enable() }, 1000), this.LoadStoreTransactions(), this.onFormChanges();
          });
      }
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null) {
        let blockID = +this.slctDmnDetails?.blockId.split(',')[0];
        Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null, 
          'district', true)]).then(() => {
            this.invtrasLstFrm.get('district')?.disable(), this.invtrasLstFrm.get('state')?.setValue(this.slctDmnDetails?.stateId ?? null),
            this.invtrasLstFrm.get('district')?.setValue(this.slctDmnDetails?.districtId ?? null), this.invtrasLstFrm.get('block')?.setValue(blockID ?? null),
              setTimeout(() => {  this.invtrasLstFrm.get('district')?.disable(), this.invtrasLstFrm.get('block')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });        
      }
      if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.invtrasLstFrm.get('state')?.setValue(null), this.invtrasLstFrm.get('district')?.setValue(null);

      if((this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null) || 
        (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null) ||
        (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null))
        this.LoadStoreTransactions(), this.onFormChanges();

      // if (this.slctDmnDetails?.districtId == null)
      //   this.LoadStoreTransactions(), this.onFormChanges();
    }
  }

  onChngDT(dtVal: any) {
    // if (this.isPgLd && dtVal != null)
      this.cstmDt = dtVal ? [] : this.cstmDtDef;
  }

  onChngDtT(dtVal2: any) {
    this.cstmDt2 = dtVal2 ? [] : this.cstmDtDef2;
  }

  onFormChanges() {
    this.invtrasLstFrm.get('storeId')?.valueChanges.subscribe(val => {
      if (val != null && val != '') {
        if (this.lstStrs.filter(a => a.storeName == val).length > 0)
          this.invtrasLstFrm.get('productId')?.setValue(null), this.shwAPrds = false;
      }
      else
        this.invtrasLstFrm.get('productId')?.setValue(null), this.shwAPrds = true;
    });
  }

  changeFn(event: any, evntType: string) {
    let evnt = (evntType == 'chng' ? event.target.value : event.value);
    if (evnt == "" || evnt == undefined)
      this.shwAPrds = true, this.slctdStrId = null;
    else {
      if (this.lstStrs.filter(a => a.storeName == evnt).length != 0)
        this.slctdStrId = this.lstStrs.filter(a => a.storeName == evnt)[0]?.storeId, this.shwAPrds = false;
      else
        this.slctdStrId = null, this.shwAPrds = true, this.invtrasLstFrm.get('storeId')?.setValue(null);
    }
    this.invtrasLstFrm.get('productId')?.setValue(''), this.slctPrdId = null, this.LoadMatsbyStoreId(this.slctdStrId);
  }

  onReset() {
    this.invtrasLstFrm.get('batchNo')?.setValue(null);
    this.slctPrdId == null; this.invtrasLstFrm.get('txnsFromDate')?.setValue(this.wkBkDt); this.invtrasLstFrm.get('txnsToDate')?.setValue(this.dt2day);
    this.invtrasLstFrm.patchValue({ txnReason: '', storeId: null, productId: null, txnType: '', isActualTxn: false }),
      this.lstShowFacTags = [], this.lstShowMatTags = [], this.slctdStrId = null, this.slctPrdId = null, this.shwAPrds = true,
      this.isShowDAT = false; // txnsFromDate: [this.wkBkDt], txnsToDate: [this.dt2day]
    if (this.lstStrs.length == 1)
      this.invtrasLstFrm.get('storeId')?.setValue(this.lstStrs[0].storeName), this.slctdStrId = this.lstStrs[0].storeId, this.shwAPrds = false,
        this.LoadMatsbyStoreId(this.slctdStrId);
        this.dmnLocation(), setTimeout(() => { this.onAS()}, 500);
    // this.LoadStoreTransactions();
  }

  onMultiSlct(type: string, ctrl: string, item: any) {
    if (type == 's') {
      if (ctrl == 'f') {
        if (this.lstSlctdFacTags.filter(f => f.id === item.id).length == 0)
          this.lstSlctdFacTags.push(item);
      }
      else {
        if (this.lstSlctdMatTags.filter(f => f.id === item.id).length == 0)
          this.lstSlctdMatTags.push(item);
      }
    }
    else if (type == 'r') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstSlctdFacTags.filter(f => f.id != item.id);
      else
        this.lstSlctdMatTags = this.lstSlctdMatTags.filter(f => f.id != item.id);
    }
    else if (type == 'a') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstFCF;
      else
        this.lstSlctdMatTags = this.lstMCF;
    }
    else {
      if (ctrl == 'f')
        this.lstSlctdFacTags = [];
      else
        this.lstSlctdMatTags = [];
    }
  }

  onCncl(ctrl: string) {
    if (ctrl == 'f')
      this.slctdFac = [...this.lstShowFacTags];
    else
      this.slctdMat = [...this.lstShowMatTags];
  }

  onTagsSave(ctrl: string) {
    if (ctrl == 'f')
      this.lstShowFacTags = [...this.lstSlctdFacTags], this.isFacAll = this.lstFCF.length === this.lstSlctdFacTags.length;
    else
      this.lstShowMatTags = [...this.lstSlctdMatTags], this.isMatAll = this.lstMCF.length === this.lstSlctdMatTags.length, this.slctPrdId = null;
  }

  onResetTags(ctrl: string) {
    if (ctrl == 'f')
      this.lstShowFacTags = [...[]], this.lstSlctdFacTags = [...[]], this.slctdFac = [...[]], this.isFacAll = false;
    else
      this.lstShowMatTags = [...[]], this.lstSlctdMatTags = [...[]], this.slctdMat = [...[]], this.isMatAll = false;
  }

  loadAllMats() {
    this.lstAllMats1 = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.product, environment.apiMthds.getAll, null, 2, true, 1).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.lstAllMats1 = this.result.data != null ? this.result.data : []; // this.lstMats = this.lstAllMats;
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }

  LoadMatsbyStoreId(sId: number) {
    if (sId && localStorage.getItem('isMatCald') == null) {
      let txnType = this.invtrasLstFrm.get('txnType')?.value;
      this.lstMats = [], this.lstAllMats = [], localStorage.setItem('isMatCald', '1'), this.FetchData(environment.apiCalls.get, environment.apiPaths.store,
          environment.apiMthds.invMatbyStoreId.replace('sss', `${sId}`).replace('ttt', `${txnType == '' ? 0 : txnType}`), null).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') { 
            if (this.result.data)
              this.lstAllMats = this.result.data?.productsDTOs != null ? this.result.data?.productsDTOs : [], this.lstMats = this.lstAllMats;
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        }
        setTimeout(() => { localStorage.removeItem('isMatCald'); }, 500);
      });
    }
  }

  changeFn2(event: any, type: any, evntType: string) {
    let evnt = (evntType == 'chng' ? event.target.value : event.value);
    if (evnt == "" || evnt == undefined)
      this.slctPrdId = null, this.invtrasLstFrm.get('productId')?.setValue(null);
    else {
      if (type == 'byStr') {
        if (this.lstMats.filter(a => a.productName == evnt).length != 0)
          this.slctPrdId = this.lstMats.filter(a => a.productName == evnt)[0]?.productId;
            if(this.slctPrdId)
            this.lstShowMatTags = [], this.onResetTags('m');
        else
          this.slctPrdId = null, this.invtrasLstFrm.get('productId')?.setValue(null);
      }
      else {
        if (this.lstAllMats1.filter(a => a.name == evnt).length != 0)
          this.slctPrdId = this.lstAllMats1.filter(a => a.name == evnt)[0]?.id; 
           if(this.slctPrdId)
           this.lstShowMatTags = [], this.onResetTags('m');
        else
          this.slctPrdId = null, this.invtrasLstFrm.get('productId')?.setValue(null);
      }
    }
  }

  LoadStoreTransactions() {
    if (this.CheckDaysDiff(this.frmDate, this.todt, false) > 30) {
      this.glblSvc.onTstr('w', 'Max 31 days should be allowed to fetch the data');
      return;
    }
    let crntPgIndx = this.crntPage - 1, payLoad = this.invtrasLstFrm.getRawValue(), fT: any[] = [], mT: any[] = [];
    this.lstShowFacTags.forEach(e => { fT.push(e.id); }), this.lstShowMatTags.forEach(e => { mT.push(e.id); }), // .name
      payLoad.storeId = payLoad.storeId ? this.slctdStrId : null, payLoad.productId = payLoad.productId ? this.slctPrdId : null,
      payLoad.pranthId = this.chsnDmnId, payLoad.isActualTxn = this.isInitTxnDate, payLoad.batchNo = (payLoad.batchNo != null &&
        payLoad.batchNo != '') ? payLoad.batchNo.toUpperCase() : null, payLoad.txnReason = payLoad.txnReason == '' ? null : payLoad.txnReason,
      payLoad.txnsToDate = payLoad.txnsToDate ? this.glblSvc.setDtFrmt(payLoad.txnsToDate, this.dtF.ymd) + this.tmF.lastTM : null, // payLoad.txnType = payLoad.txnType == '' ? null : +payLoad.txnType;
      payLoad.storeBadge = fT.length != 0 ? fT : null, payLoad.productBadge = mT.length != 0 ? mT : null, this.lstTrans = [];
    if (this.isPgLd)
      payLoad.txnsFromDate = this.glblSvc.setDtFrmt(this.wkBkDt, this.dtF.ymd) + this.tmF.zeroTM;
    else
      payLoad.txnsFromDate = payLoad.txnsFromDate ? this.glblSvc.setDtFrmt(payLoad.txnsFromDate, this.dtF.ymd) + this.tmF.zeroTM : null;
    if (localStorage.getItem('isTransAPICald') == null) {
      localStorage.setItem('isTransAPICald', '1'), this.FetchData(environment.apiCalls.post, environment.apiPaths.store, environment.apiMthds.transList.
          replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`), payLoad, 0).then(() => { this.isPgLd = false;
        if (this.result) {
          if (this.result.status == 'OK') {
            this.totalItems = this.result.data?.totalRecordCount ?? 0,
              this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems);
            let result = this.result.data?.storesTransactionDTOs != null ? this.result.data?.storesTransactionDTOs : [];
            if (result)
              result.forEach((e: any) => { Object.assign(e, { isB: false, lstBats: [] }); }), this.lstTrans = result,
                this.isShowDAT = this.isInitTxnDate;
          }
        }
        setTimeout(() => { localStorage.removeItem('isTransAPICald') }, 500);
      });
    }
  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo, this.LoadStoreTransactions();
  }

  clearBSDate(ctrl: string) {
    this.invtrasLstFrm.get(ctrl)?.setValue('');
  }

  LoadBatches(indx: any, kId: any, mId: any, tId: any) {
    // if (this.userlgndtls == null || this.userlgndtls?.viewDetails) {
      this.lstTrans[indx].lstBats = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.txn,
          environment.apiMthds.getTxnBatchbyMatId.replace('kkk', kId).replace('mmm', mId).replace('ttt', tId), null).then(() => {
        if (this.result) {
          if (this.result.status == 'OK')
            this.lstTrans[indx].lstBats = this.result.data;
        }
      });
    // }
  }

  onEE() {
    if (this.invtrasLstFrm.get('txnsFromDate')?.value == null || this.invtrasLstFrm.get('txnsToDate')?.value == null ||
        this.invtrasLstFrm.get('txnsFromDate')?.value == '' || this.invtrasLstFrm.get('txnsToDate')?.value == '')
      this.glblSvc.onTstr('w', 'Please choose both dates..');
    else {
      let dtDiff = this.CheckDaysDiff(new Date(this.invtrasLstFrm.get('txnsFromDate')?.value), new Date(this.invtrasLstFrm.get('txnsToDate')?.value)), slctdDmn = this.chsnDmnName;
      if (dtDiff >= 31 && slctdDmn =='India')
        this.glblSvc.onTstr('i', 'Date range should not exceed more than 30 days..');
      else if (dtDiff >= 181 && slctdDmn !='India')
        this.glblSvc.onTstr('i', 'Date range should not exceed more than 180 days..');
      else {
        let dtDiff = this.CheckDaysDiff(new Date(this.invtrasLstFrm.get('txnsFromDate')?.value), new Date(this.invtrasLstFrm.get('txnsToDate')?.value)), slctdDmn = this.chsnDmnName;
        if (dtDiff >= 31 && slctdDmn =='India')
          this.glblSvc.onTstr('i', 'Date range should not exceed more than 30 days..');
        else if (dtDiff >= 181 && slctdDmn !='India')
          this.glblSvc.onTstr('i', 'Date range should not exceed more than 180 days..');
        else {
          let payLoad = this.invtrasLstFrm.getRawValue(), fT: any[] = [], mT: any[] = [], ddlVal = $('#ee').val();
          this.lstShowFacTags.forEach(e => { fT.push(e.id); }), this.lstShowMatTags.forEach(e => { mT.push(e.id); }), // .name
            payLoad.pranthId = this.chsnDmnId, payLoad.txnsFromDate = payLoad.txnsFromDate ? this.glblSvc.
              setDtFrmt(payLoad.txnsFromDate, this.dtF.ymd) + this.tmF.zeroTM : null, payLoad.txnsToDate = payLoad.txnsToDate ?
            this.glblSvc.setDtFrmt(payLoad.txnsToDate, this.dtF.ymd) + this.tmF.lastTM : null, payLoad.storeId = payLoad.storeId ?
            this.slctdStrId : null, payLoad.productId = payLoad.productId ? this.slctPrdId : null, payLoad.storeBadge = fT.length != 0 ?
              fT : null, payLoad.productBadge = mT.length != 0 ? mT : null, payLoad.txnReason = payLoad.txnReason == '' ? null :
              payLoad.txnReason, payLoad.isActualTxn = this.isInitTxnDate, payLoad.batchNo = (payLoad.batchNo != null &&
                payLoad.batchNo != '') ? payLoad.batchNo.toUpperCase() : null;
          if (ddlVal == 'nb') {
            this.FetchData(environment.apiCalls.post, environment.apiPaths.store, environment.apiMthds.exprttrans.
                replace('rrr', `${this.chsnDmnId}`).replace('iii', this.usrLgnDtls.userId).
                replace('nnn', this.usrLgnDtls.userName).replace('eml', this.usrLgnDtls.email), payLoad, 10, true, 2).then(() => {
              if (this.result)
                this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
            });
          }
          else {
            this.FetchData(environment.apiCalls.post, environment.apiPaths.store, environment.apiMthds.exprtTransWB.
                replace('rrr', `${this.chsnDmnId}`).replace('iii', this.usrLgnDtls.userId).replace('nnn', this.usrLgnDtls.userName).
                replace('eml', this.usrLgnDtls.email), payLoad, 10, true, 2).then(() => {
              if (this.result)
                this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
            });
          }
        }
      }
    }
  }

  onLocChng(evnt: any, type: any, isId: boolean = false) {
    let sid = '', did = '', slctdId = isId ? evnt : evnt.target.value;
    if (slctdId != "null") {
      if (type == 'state')
        sid = slctdId, this.dstLst = [], this.blkLst = [], this.invtrasLstFrm.get('district')?.setValue(null),
          this.invtrasLstFrm.get('block')?.setValue(null);
      else
        did = slctdId, this.blkLst = [], this.invtrasLstFrm.get('block')?.setValue(null);
      this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'state' ? environment.apiMthds.getDistricts.
          replace('sss', sid) : environment.apiMthds.getBlocks.replace('ddd', did)), null, 2, true, 1).then(() => {
        if (this.result) {
          if (this.result.data) {
            if (type == 'state')
              this.dstLst = this.result.data != null ? this.result.data : [], this.invtrasLstFrm.get('district')?.enable(),
                this.invtrasLstFrm.get('block')?.disable();
            else
              this.blkLst = this.result.data != null ? this.result.data : [], this.invtrasLstFrm.get('block')?.enable();
          }
        }
      })
    }
    else {
      if (type == 'state')
        this.invtrasLstFrm.get('district')?.setValue(null), this.invtrasLstFrm.get('block')?.setValue(null),
          this.invtrasLstFrm.get('district')?.disable(), this.invtrasLstFrm.get('block')?.disable();
      else
        this.invtrasLstFrm.get('block')?.setValue(null), this.invtrasLstFrm.get('block')?.disable();
    }
  }

  onAS() {
    if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.invtrasLstFrm.get('state')?.enable(), this.invtrasLstFrm.get('district')?.disable(), this.invtrasLstFrm.get('block')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.invtrasLstFrm.get('state')?.disable(), this.invtrasLstFrm.get('district')?.enable(), this.invtrasLstFrm.get('block')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null)
       this.invtrasLstFrm.get('state')?.disable(), this.invtrasLstFrm.get('district')?.disable(), this.invtrasLstFrm.get('block')?.enable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null)
      this.invtrasLstFrm.get('state')?.disable(), this.invtrasLstFrm.get('district')?.disable(), this.invtrasLstFrm.get('block')?.disable();
  }

}